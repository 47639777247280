<template>
	<div class="list-container">
    <vuetable
      ref="vuetable"
      :rowClass="rowClass"
      :api-mode="false"
      :fields="columns"
      :data-manager="dataManager"
      :css="css.table"
      :track-by="trackBy"
      :sort-order="innerSortOrder"
      :detail-row-component="detailRow"
      :detail-row-options="detailOptions"
      :no-data-template="noDataTemplate"
      pagination-path="pagination"
      @vuetable:row-clicked="handleRowClicked"
      @vuetable:cell-clicked="(props) => $emit('cell-clicked', props)"
      @vuetable:pagination-data="onPaginationData"
    >
      <template v-slot:empty-result>
        <slot name="empty-result"></slot>
      </template>
    </vuetable>
    <div class="pt-2">
      <vuetable-pagination
        ref="pagination"
        :css="css.pagination"
        @vuetable-pagination:change-page="onChangePage"
      />
    </div>
	</div>
</template>

<script>
import {Vuetable, VuetablePagination} from 'vue3-vuetable';
import _ from "lodash";
import NotifyMixin from "@/mixins/NotifyMixin";

const css = {
  table: {
    tableClass: 'table-auto w-full table',
    tableBodyClass: '',
    tableHeaderClass: 'px-4 py-2',
    tableWrapper: 'overflow-x-auto flex-1',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
    detailRowClass: 'bg-blue-100',
  },
  pagination: {
    wrapperClass: 'flex justify-center py-4',
    activeClass: 'active',
    disabledClass: '',
    pageClass: 'btn-paging',
    linkClass: 'btn-paging',
    paginationClass: '',
    paginationInfoClass: 'pagination-info',
    dropdownClass: '',
    icons: {
      first: '',
      prev: '',
      next: '',
      last: '',
    }
  },
};

export default {
  name: "app",
  mixins: [NotifyMixin],
  props: {
    css: {
      type: Object,
      default() {
        return css;
      },
    },
  },

  components: {
    Vuetable,
    VuetablePagination
  },
  emits: ['click', 'cell-clicked'],

  computed: {
    columns() {
      return [
        {
          name: 'quext_field_name',
          title: 'quext field name',
          class: 'w-1/4',
        },
        {
          name: 'quext_field_data_type',
          title: 'quext data type',
          class: 'w-1/4',
        },
        {
          name: 'quext_concept_name',
          title: 'quext concept',
          class: 'w-1/4',
        },
      ]
    }
  },

  data() {
    return {
      perPage: 9,
      data: []
    };
  },

  watch: {
    data() {
      this.$refs.vuetable.refresh();
    }
  },

  async mounted() {
    await this.$leasingDataProvider.getList('quext_field_concept')
      .then(({data}) => this.data = data)
      .catch(error => this.notifyError(error.message))
      .finally(() => this.loading = false);
  },

  methods: {
    handleRowClicked(row) {
      this.$emit('click', { data: row.data, index: row.index });
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    }
  }
};
</script>

<style scoped>
  .list-container {
    @apply relative flex flex-col h-full;
  }
 .list-container :deep(.sort-icon) {
    float: none !important;
    display: inline-block;
    margin-left: 0.5rem;
 }

  .list-container :deep(.vuetable) {
    @apply text-black;
  }
  .list-container :deep(.vuetable th) {
    @apply text-sm font-400 border-b;
  }

  .list-container :deep(.vuetable-empty-result) {
    @apply bg-blue-50 text-blue-700 font-500 text-sm text-center p-6;
    box-shadow: inset 0 0 0 1px #C4DEF0;
  }

 /* remove table hover effect */
  .list-container :deep(.table-row-nonclickable:hover) {
    @apply bg-white;
  }
  .list-container :deep(.table-row-nonclickable:nth-child(even):hover) {
    @apply bg-gray-100;
  }

  /* detail row */
  .list-container :deep(.vuetable-detail-row),
  .list-container :deep(.table-row-detail-open),
  .list-container :deep(.table-row.table-row-detail-open:hover) {
    @apply bg-blue-50;
  }
  .list-container :deep(.vuetable-detail-row > td) {
    box-shadow: inset 0 -1px 0 0 #C4DEF0, inset -1px 0 0 0 #C4DEF0, inset 1px 0 0 0 #C4DEF0;
  }
  .list-container :deep(.table-row-detail-open > td) {
    box-shadow: inset 0 1px 0 0 #C4DEF0;

    &:first-child {
       box-shadow: inset 1px 1px 0 0 #C4DEF0;
     }
    &:last-child {
       box-shadow: inset -1px 1px 0 0 #C4DEF0;
     }
  }
</style>
