<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
    <div class="text-blue-500 mt-2">
      <button
        class="cursor-pointer border-b border-blue-500 font-bold"
        @click="isOpen = true"
      >
        add new field
        <icon name="plus" class="h-4 w-4 inline text-blue-500 cursor-pointer" />
      </button>
    </div>
    <ListQuextFields
      @click="handleRowClicked"
      :key="key"
    />
    <div class="z-50 bottom-0 left-0 fixed w-full bg-white pb-6" v-if="isOpen || is_on_edit_mode">
      <p class="bg-gray-300 py-2 px-8 font-bold">{{isOpen ? 'add new field' : `editing quext field: ${field_to_edit.quext_field_name}`}}</p>
      <div class="w-full p-8">
        <div class="form-row">
          <div class="form-col">
            <label for="quext_field_name">field name</label>
            <input
              name="quext_field_name"
              type="text"
              class="form-input"
              v-model="field.quext_field_name"
            />
            <span v-if="hasError('field.quext_field_name')" class="form-error">
              {{ showError("field.quext_field_name") }}
            </span>
          </div>
          <div class="form-col">
            <label for="quext_field_label">field label</label>
            <input
              name="quext_field_label"
              type="text"
              class="form-input"
              v-model="field.quext_field_label"
            />
            <span v-if="hasError('field.quext_field_label')" class="form-error">
              {{ showError("field.quext_field_label") }}
            </span>
          </div>
          <div class="form-col">
            <label for="quext_field_data_type">data field type</label>
            <dropdown
              :options="data_types"
              :placeholder="'select partner document'"
              v-model="field.quext_field_data_type"
            ></dropdown>
            <span v-if="hasError('field.quext_field_data_type')" class="form-error">
              {{ showError("field.quext_field_data_type") }}
            </span>
          </div>
          <div class="form-col">
            <label for="quext_concept_name">data field concept</label>
            <dropdown
              :options="dataConcepts"
              :placeholder="'select partner document'"
              v-model="field.quext_concept_name"
            ></dropdown>

            <span v-if="hasError('field.quext_concept_name')" class="form-error">
              {{ showError("field.quext_concept_name") }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex justify-between py-2 px-8">
        <div class="">
          <button @click="handleCancel">cancel</button>
        </div>
        <div>
          <button v-if="is_on_edit_mode" @click.stop="handleDelete" class="btn-danger mx-4 btn-solid">
            delete field
          </button>
          <button v-if="is_on_edit_mode" @click.stop="updateField" class="btn-primary btn-solid">
            update and close
          </button>
          <button v-else @click.stop="saveAndClose" class="btn-primary btn-solid">
            save and close
          </button>
        </div>
      </div>
    </div>
    <modal-footer v-if="!isOpen && !is_on_edit_mode" :footer="footer" :tertiary="cancel" />
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import Icon from "@/components/ui/Icon";
import Loader from "@/components/ui/Loader";
import NotifyMixin from "@/mixins/NotifyMixin";
import EventBus from "@/utils/EventBus";
import ListQuextFields from "@/views/lease/PartnerDocuments/ListQuextFields.vue";
import Dropdown from "@/components/ui/Dropdown";

import { mapGetters } from "vuex";

export default {
  name: "ManageQuextFields",
  components: { Loader, ModalFooter, Icon, Dropdown, ListQuextFields },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  data() {
    return {
      loading: false,
      partner: null,
      quextFields: [],
      toDelete: "",
      isOpen: false,
      field: {
        quext_field_name: "",
        quext_field_label: "",
        quext_field_data_type: null,
        quext_concept_name: null
      },
      footer: {
        tertiaryButton: "cancel"
      },
      field_to_edit: null,
      is_on_edit_mode: false,
      dataConcepts: [],
      tagsInput: [],
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getPartner: "leases/getPartner",
      getQuextFields: "leases/getQuextFields",
    }),
    data_types() {
      return [{
        key: 'string',
        value: 'string'
      },
      {
        key: 'numeric',
        value: 'numeric'
      },
      {
        key: 'date',
        value: 'date'
      },
      {
        key: 'boolean',
        value: 'boolean'
      }]
    }
  },
  watch: {
    getQuextFields: function () {
      this.quextFields = [];
      
    },
  },
  methods: {
    handleCancel() {
      this.isOpen = false;
      this.is_on_edit_mode = false;
      this.field = {
        quext_field_name: "",
        quext_field_label: "",
        quext_field_data_type: null,
        quext_concept_name: null
      };
    },
    handleRowClicked(row) {
      this.field_to_edit = row.data;
      this.loading = true;
      this.$leasingDataProvider.getOne('quext_field', {
          quext_field_name: row.data.quext_field_name,
          quext_field_data_type: row.data.quext_field_data_type,
        })
        .then(({data}) => {
          this.field = {
            quext_field_name: row.data.quext_field_name,
            quext_field_data_type: row.data.quext_field_data_type,
            quext_concept_name: row.data.quext_concept_name,
            quext_field_label: data.quext_field_label
          };
        })
        .catch(() => this.notifyError('Something went wrong, please try again later.'))
        .finally(() => this.loading = false);

      this.is_on_edit_mode = true;
    },
    handleDelete() {
      EventBus.emit("show-modal-confirmation", {
        id: "lease-service-manage_quext_fields",
        title: "manage quext fields",
        showConfirmation: true,
        confirmationMessage: `Are you sure you want to delete this quext field: ${this.field.quext_field_name}`,
        confirmationType: "warning",
      });
    },
    onDelete() {
      this.loading = true;
      this.$leasingDataProvider.delete('quext_field', {
          quext_field_name: this.field.quext_field_name,
          quext_field_data_type: this.field.quext_field_data_type,
        })
        .then(() => {
          this.key += 1;
          this.handleCancel();
          this.notifySuccess("the quext field was deleted successfully");
        })
        .catch((error) => this.notifyError('Something went wrong, please try again later.', error))
        .finally(() => this.loading = false);
    },
    async addQuextConcept (newConcept) {
      if (newConcept) {
        this.loading = true;
        await this.$leasingDataProvider.create('quext_concept', {
          data: {
            name: newConcept
          }
        })
        .then((res) => {
          if (res.status === 201) {
            this.key += 1;
            this.handleCancel();
            this.notifySuccess("the quext concept was created successfully");
          }
        })
        .catch(() => this.notifyError('Something went wrong, please try again later.'))
        .finally(() => this.loading = false);
      }
    },
    async addDataType(newDataType) {
      if (newDataType) {
        this.loading = true;
        await this.$leasingDataProvider.create('quext_data_Type', {
          data: {
            name: newDataType
          }
        })
        .then(async (res) => {
          if (res.status === 201) {
            await this.fetchDataTypes();
            this.notifySuccess("the quext data type was created successfully");
          }
        })
        .catch(() => this.notifyError('Something went wrong, please try again later.'))
        .finally(() => this.loading = false);
      }
    },
    fetchQuextConcepts() {
      this.loading = true;
      this.$leasingDataProvider.getList('quext_concept')
        .then((res) => {
          this.dataConcepts = res.data.map(concept => {
            return {
              key: concept.quext_concept_name,
              value: concept.quext_concept_name
            }
          });
        })
        .catch(() => this.notifyError('Something went wrong, please try again later.'))
        .finally(() => this.loading = false);
    },
    saveAndClose() {
      if (this.validator.passes()) {
        this.loading = true;
        this.$leasingDataProvider.create('quext_field', {
          data: {
            quext_field_name: this.field.quext_field_name,
            quext_field_data_type: this.field.quext_field_data_type,
            quext_field_label: this.field.quext_field_label
          }
        })
        .then(() => {
          this.key += 1;
          this.handleCancel();
          this.notifySuccess("the field was created successfully");
        })
        .catch(() => this.notifyError('Something went wrong, please try again later.'))
        .finally(() => this.loading = false);
      }
    },
    updateField() {
      if (this.validator.passes()) {
        this.loading = true;
        this.$leasingDataProvider.patch('quext_field', {
          quext_field_name: this.field_to_edit.quext_field_name,
          quext_field_data_type: this.field_to_edit.quext_field_data_type,
          data: {
            quext_field_name: this.field.quext_field_name,
            quext_field_data_type: this.field.quext_field_data_type,
            quext_field_label: this.field.quext_field_label
          }
        })
        .then(() => {
          this.key += 1;
          this.handleCancel();
          this.notifySuccess("the field was updated successfully");
        })
        .catch(() => this.notifyError('Something went wrong, please try again later.'))
        .finally(() => this.loading = false);
      }
    },
    cancel() {
      this.$router.push({ name: "manage-partner-documents.index" });
    },
  },
  validations: {
    "field.quext_field_name": "required | is_empty",
    "field.quext_field_data_type": "required"
  },
  created() {
    this.fetchQuextConcepts();
    EventBus.on("confirm-lease-service-manage_quext_fields", () => {
      this.onDelete();
    });
    EventBus.on(
      "cancel-confirmation-lease-service-manage_quext_fields",
      () => {
        this.toDelete = "";
      }
    );
    this.initValidator();
    this.validator.extend("is_empty", function (value) {
      return !value.match(/^\s+$/);
    });
    this.validator.setCustomErrorMessages({
      "field.quext_field_name.is_empty": "this field is empty."
    });
    this.partner = this.getPartner;
  },
};
</script>
<style scoped>
ul:before {
  content: attr(aria-label);
  font-size: 1rem;
  font-weight: bold;
  color: black;
}
ul > li {
  @apply py-3 font-light text-black;
}
.inputs-wrap {
  @apply pb-2;
  height: calc(72vh - var(--menuHeight));
  overflow-y: auto;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
